<template>
    <div id="app">
        <router-view/>
    </div>
</template>

<script>
export default {
    data(){
        return {
            timer: null,
            count: 0,
        };
    },
    created(){
        this.init();
    },
    methods: {
        init(){
            document.addEventListener("mousemove", this.reset, false);
            document.addEventListener("mousedown", this.reset, false);
            document.addEventListener("keypress", this.reset, false);
            document.addEventListener("touchmove", this.reset, false);
            document.addEventListener("onscroll", this.reset, false);

            this.onTimer();
        },
        reset(){
            this.count = 0;
        },
        onTimer(){
            clearTimeout();
            this.timer = setTimeout(() => {
                if((this.refreshToken && 1800 < this.count) || (this.refreshToken && this.payload.exp * 1000 <= Date.now()) ){
                    this.$store.dispatch("logout");
                }
                this.count += 1;
                this.onTimer();
            }, 1000);
        },
    },
    computed: {
        refreshToken(){
            return this.$store.state.refreshToken;
        },
        accessToken(){
            return this.$store.state.accessToken;
        },
        payload(){
            return JSON.parse(atob(this.refreshToken?.split('.')[1] || '') || "{}");
        }
    },
    watch: {
        accessToken(){
            if(!this.accessToken){
                alert("로그아웃 되었습니다");
                this.$router.push("/");
            }
        }
    }
}
</script>