import './plugins/prototype';

import Vue from 'vue'
import router from './router'
import store from './store'

import './plugins/axios'
import './plugins/vue-cookies';
import './plugins/vue-meta';
import './plugins/vue-homecc';

import App from './AppMain.vue'

// SVG ICONS
import iconBase from '@/components/common/svg/icon-base.vue';
import binRounded from '@/components/common/svg/icons/bin-rounded.vue';
import closeRounded from '@/components/common/svg/icons/close-rounded.vue';
Vue.component("icon-base", iconBase);
Vue.component("bin-rounded", binRounded);
Vue.component("close-rounded", closeRounded);

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')